import React, { Component } from 'react';
import PropTypes from "prop-types";

class Services extends Component {
  render() {
      //Service loop start
      const servicedata = this.props.servicesData.map((service, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
                <div className="glyph">
                    <i className={service.icon} />
                </div>
                <h3>{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END
    return (
        <>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div
                                className="section-title"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                            >
                                <h2>{this.props.sectionTitle}</h2>
                                <p>{this.props.sectionDescription}</p>
                                <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        {servicedata}

                        <div className="col-md-6 col-lg-4 text-left" key="4">
                            <div className="service-item" style={{ paddingLeft: 50 }}>
                                <div className="glyph">
                                    {/*<i className={service.icon} />*/}
                                </div>
                                {/*<h3>Test Header</h3>
                                <p> Test Description</p>*/}

                                <ul>
                                    <li>&#8718; Integrated Facilities Management</li>
                                    <li>&#8718; Operations</li>
                                    <li>&#8718; Maintenance</li>
                                    <li>&#8718; Technical Due Diligence</li>
                                    <li>&#8718; Energy Management</li>
                                    <li>&#8718; Green Building Consultancy</li>
                                    <li>&#8718; Third Party Commissioning Agent</li>
                                </ul>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 text-left" key="5">
                            <div className="service-item" style={{ paddingLeft: 50 }}>
                                <div className="glyph">
                                    {/*<i className={service.icon} />*/}
                                </div>
                                {/*<h3>Test Header</h3>
                                <p> Test Description</p>*/}

                                <ul>
                                    <li>&#8718; Security</li>
                                    <li>&#8718; Cleaning and Janitorial</li>
                                    <li>&#8718; Hygiene and Pest Control</li>
                                    <li>&#8718; Waste Management & Handling</li>
                                    <li>&#8718; Landscaping and Grounds</li>
                                    <li>&#8718; Maintenance</li>
                                    <li>&#8718; People Experience</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 text-left" key="6">
                            <div className="service-item" style={{ paddingLeft: 50 }}>
                                <div className="glyph">
                                    {/*<i className={service.icon} />*/}
                                </div>
                                {/*<h3>Test Header</h3>
                                <p> Test Description</p>*/}

                                <ul>
                                    <li>&#8718; Facade Cleaning</li>
                                    <li>&#8718; Duct Cleaning</li>
                                    <li>&#8718; Clean Room cleaning</li>
                                    <li>&#8718; Project Management</li>
                                    <li>&#8718; Renovation</li>
                                    <li>&#8718; Mover</li>
                                    <li>&#8718; Office relocation</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 text-left" key="7">
                            <div className="service-item" style={{ paddingLeft: 50 }}>
                                <div className="glyph">
                                    {/*<i className={service.icon} />*/}
                                </div>
                                {/*<h3>Test Header</h3>
                                <p> Test Description</p>*/}

                                <h6>What We Do: </h6>

                                <ul>
                                    <li>&#8728; Heating, Ventilation, Air Cond.</li>
                                    <li>&#8728; Generator Maintenance</li>
                                    <li>&#8728; Low Current Maintenance</li>
                                    <li>&#8728; High Voltage Maintenance</li>
                                    <li>&#8728; Mechanical Ventilation</li>
                                    <li>&#8728; UPS Maintenance</li>
                                    <li>&#8728; Fire Protection Services</li>
                                    <li>&#8728; Power & Electrical</li>
                                    <li>&#8728; Kitchen Equipment</li>
                                    <li>&#8728; Sanitary & Plumbing</li>
                                    <li>&#8728; Furniture Fixtures</li>
                                    <li>&#8728; Lighting Maintenance</li>
                                    <li>&#8728; Energy Management</li>
                                    <li>&#8728; Project Management</li>
                                    <li>&#8728; Building Installations & Fabric Maintenance</li>
                                </ul>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 text-left" key="8">
                            <div className="service-item" style={{ paddingLeft: 50 }}>
                                <div className="glyph">
                                    {/*<i className={service.icon} />*/}
                                </div>
                                {/*<h3>Test Header</h3>
                                <p> Test Description</p>*/}

                                <h6>What We Do: </h6>

                                <ul>
                                    <li>&#8728; Clean Room</li>
                                    <li>&#8728; Janitorial</li>
                                    <li>&#8728; Housekeeping</li>
                                    <li>&#8728; Hygiene</li>
                                    <li>&#8728; Waste Management & Handling</li>
                                    <li>&#8728; Pest Control</li>
                                    <li>&#8728; Fixtures Cleaning</li>
                                    <li>&#8728; Duct Cleaning</li>
                                    <li>&#8728; High Level Cleaning</li>
                                    <li>&#8728; Landscaping, Grounds Maintenance</li>
                                    <li>&#8728; Security</li>
                                    <li>&#8728; Reception</li>
                                    <li>&#8728; Conference Room Management</li>
                                    <li>&#8728; Courier</li>
                                    <li>&#8728; Mail Handling / Post Room</li>
                                    <li>&#8728; Laundry</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 text-left" key="9">
                            <div className="service-item" style={{ paddingLeft: 50 }}>
                                <div className="glyph">
                                    {/*<i className={service.icon} />*/}
                                </div>
                                {/*<h3>Test Header</h3>
                                <p> Test Description</p>*/}

                                <h6>What We Do: </h6>

                                <ul>
                                    <li>&#8728; Spiderweb works</li>
                                    <li>&#8728; Signboard cleaning maintenance</li>
                                    <li>&#8728; Painting works</li>
                                    <li>&#8728; Building coating</li>
                                    <li>&#8728; Glass & window frames</li>
                                    <li>&#8728; Ceiling of buildings</li>
                                    <li>&#8728; Silicone work</li>
                                    <li>&#8728; Cracks & leakage</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
  }
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Services",
    sectionTitle: "Services",
    sectionDescription:
        "We offer a comprehensive suite of facilities management services designed to ensure the seamless operation, safety, and sustainability of your facility. Our services encompass everything from maintenance and security to energy efficiency and sustainability initiatives. ",

    servicesData: [
        {
            icon: "icofont-automation",
            heading: "Hard Services Suite",
            description:
                "Encompasses a broad array of critical services aimed at managing and maintaining the physical infrastructure of your facility. Our suite includes:"
        },
        {
            icon: "icofont-laptop-alt",
            heading: "Soft Services Suite",
            description:
                "comprises a comprehensive range of services aimed at enhancing the well-being and satisfaction of occupants within your facility. Our suite includes:"
        },
        {
            icon: "icofont-woman-in-glasses",
            heading: "Specialized Services",
            description:
                "We offer a range of specialized services and manage minor projects tailored to address these specific needs. Our specialized services include:"
        },
        /*{
            icon: "icofont-chart-growth",
            heading: "SEO & Advertising",
            description:
                "We strive to embrace and drive change in our industry which allows us to keep our clients relevant."
        },
        {
            icon: "icofont-network-tower",
            heading: "Marketing & Consulting",
            description:
                "We strive to embrace and drive change in our industry which allows us to keep our clients relevant."
        },
        {
            icon: "icofont-bullseye",
            heading: "Design & Development",
            description:
                "We strive to embrace and drive change in our industry which allows us to keep our clients relevant."
        },*/
    ]
};

export default Services;
