import React, { Component } from "react";

class TopFeatures extends Component {
  render() {

    //TopFeatures loop start
    const topfeaturesdata = this.props.topFeaturesData.map((feature, index) => (
      <div className="col-md-6 col-lg-4 text-center" key={index}>
        <div className="top-features-item">
          <div className="glyph">
            <i className={feature.icon} />
          </div>
          <h3>{feature.heading}</h3>
          <p>{feature.description}</p>
        </div>
      </div>
    ));

    //TopFeatures loop END
    return (
      <>
        <div className="top-features">
          <div className="container">
            <div className="row justify-content-center">
              {topfeaturesdata}
            </div>
          </div>
        </div>
      </>
    );
  }
}

//Default Props
TopFeatures.defaultProps = {
  topFeaturesData: [
    {
      icon: "icofont-rocket-alt-1",
      heading: "Quality Assurance",
      description:
        "We pledge to consistently deliver high-quality services that meet and exceed industry standards. Our commitment to quality ensures that your facilities operate at peak efficiency and safety.",
    },
    {
      icon: "icofont-box",
      heading: "Customer Satisfaction",
      description:
        "We are committed to understanding and fulfilling our clients' unique needs. Your satisfaction is our driving force, and we work tirelessly to tailor our services to your specific requirements.",
    },
    {
      icon: "icofont-live-support",
      heading: "Professionalism",
      description:
        "Our team is comprised of skilled and experienced professionals who take their commitment to excellence seriously. You can rely on us for a professional and courteous service experience.",
    }
  ],
};

export default TopFeatures;
