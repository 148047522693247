import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import emailjs from "emailjs-com";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            subject: "",
            number: "",
            message: "",
            successMsg: "",
            to_name: 'Enquiry Team',
            to: 'enquiry@vdsservices.my',
            cc: '',
        };
    }
   /* handleForm = e => {
        let that = this;
        axios
            .post("https://formcarry.com/s/qJalZsYuqY-", this.state, {
                headers: { Accept: "application/json" }
            })
            .then(function(response) {
                document.getElementById("contactForm").reset();
                that.setState({
                    successMsg: "Thank you! We received your message"
                });
                document.getElementById("contactForm").reset();
            })
            .catch(function(error) {});
    };*/

    handleFields = e => this.setState({ [e.target.name]: e.target.value });

    sendEmail = (e) => {
        e.preventDefault();

        emailjs.send('service_3u8qwdh', 'template_7iktswu', this.state, 'Jgu9ylWLgNYZ7gSud')
            .then((result) => {
                console.log(result.text);
                this.setState({
                    successMsg: "Thank you! We received your message"
                });
                e.target.reset();
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    successMsg: "Sorry! Send Message Unsuccessful." + error.text
                });
                e.target.reset();
            });
    };

    render() {
        return (
            <>
                <section id="contact" className="contact-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <div
                                    className="section-title"
                                    data-aos="fade-up"
                                    data-aos-delay="30"
                                    data-aos-duration="1000"
                                >
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <div className="address-area">
                                    <div className="addess">
                                        <i className="icofont-google-map"/>
                                        <h4>{this.props.AddTitle}</h4>
                                        <p>{this.props.Address}</p>
                                    </div>
                                    <div className="email">
                                        <i className="icofont-email"/>
                                        <h4>{this.props.EmailTitle}</h4>
                                        <p>{this.props.Email}</p>
                                    </div>
                                    <div className="phone">
                                        <i className="icofont-phone"/>
                                        <h4>{this.props.PhoneTitle}</h4>
                                        <p>{this.props.Phone}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">
                                <div className="contact-form">
                                    <form
                                        id="contactForm"
                                        /*onSubmit={(e, formData, inputs) => {
                                            e.preventDefault();
                                            this.handleForm(formData);
                                        }}*/

                                        onSubmit={this.sendEmail}
                                    >
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        name="name"
                                                        id="name"
                                                        required
                                                        className="form-control"
                                                        placeholder="Name"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        name="email"
                                                        id="email"
                                                        type="email"
                                                        required
                                                        className="form-control"
                                                        placeholder="Email"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        name="subject"
                                                        id="subject"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Subject"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        name="number"
                                                        id="number"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Phone"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <textarea
                                                        name="message"
                                                        id="description"
                                                        placeholder="Your message"
                                                        className="form-control"
                                                        required
                                                        rows="5"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="center-wrap">
                                            <div className="button">
                                                <button type="submit">Submit <i className="icofont-long-arrow-right"/> </button>
                                                <div className="mask"></div>
                                            </div>
                                        </div>
                                        <div className="clearfix" />
                                    </form>
                                    {this.state.successMsg !== "" ? (
                                        <div className="col-md-12">
                                            <div id="contact_send_status">
                                                <h3 className="contactMsg">
                                                    {this.state.successMsg}
                                                </h3>
                                            </div>
                                         </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

//Props Types
Contact.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    AddTitle: PropTypes.string,
    Address: PropTypes.string,
    EmailTitle: PropTypes.string,
    Email: PropTypes.string,
    PhoneTitle: PropTypes.string,
    Phone: PropTypes.string,
};

//Default Props
Contact.defaultProps = {
    SectionbgTitle: "Contact",
    sectionTitle: "Our Contact",
    sectionDescription: "At VDS Services, we value communication as a cornerstone of our business. Our dedicated team is readily available to address your inquiries, collaborate on projects, or discuss partnerships.",
    AddTitle: "Address",
    Address: "No 37-G, Jalan Puj 3/5, Taman Puncak Jalil, Bandar Putra Permai,43300, Seri Kembangan, Selangor Darul Ehsan",
    EmailTitle: "Email",
    Email: "enquiry@vdsservices.my",
    PhoneTitle: "Phone",
    Phone: "+6012-386 8165",
};
export default Contact;
