import React, { Component } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'react-router-dom';
import { Pagination } from "swiper";

class Partners extends Component {
    render() {
        //Partner loop start
        const partnerData = this.props.partnersData.map((partner, index) => (
            <SwiperSlide key={index}>
                <div className="single-partner-logo">
                    <Link to={partner.partnerLink} className="logo-preview">
                        <img src={partner.partnerLogo} alt="partnerLogo" />
                    </Link>
                </div>
            </SwiperSlide>
        ));
        //Partner loop END
        return (
            <>
                <section className="our-partners ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <div 
                                    className="section-title"
                                    data-aos="fade-up"
                                    data-aos-delay="30"
                                    data-aos-duration="1000"
                                >
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div> 
                            </div>
                        </div>

                        <div className="row">
                            <Swiper
                                spaceBetween={100}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                    576: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                        spaceBetween: 40,
                                    },
                                    992: {
                                        slidesPerView: 5
                                    },
                                }}
                                modules={[Pagination]}
                                className="partners-slides"
                            >
                                {partnerData}
                            </Swiper>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

//Props Types
Partners.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    partnersData: PropTypes.array
};

//Default Props
Partners.defaultProps = {
    SectionbgTitle: "Partners",
    sectionTitle: "Our Partners",
    sectionDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.",
    partnersData: [
        {
            partnerLogo: require("../assets/img/partners-logo/img1.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/partners-logo/img2.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/partners-logo/img3.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/partners-logo/img4.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/partners-logo/img5.png"),
            partnerLink: "/#0"
        },
        {
            partnerLogo: require("../assets/img/partners-logo/img3.png"),
            partnerLink: "/#0"
        }
    ]
};
export default Partners;
