import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import slideBgImg1 from '../../assets/img/slide-bg-1.jpg';
import slideBgImg2 from '../../assets/img/slide-bg-2.jpg';
import slideBgImg3 from '../../assets/img/slide-bg-3.jpg';

const bannerData = [
    {
        id: "1",
        SlideBgImg: `${slideBgImg1}`,
        Title: "VDS Services",
        TopTitle: "We are a passionate team dedicated to be a market leader in the",
        Content:
            "FACILITIES MANAGEMENT SERVICES",
        BtnLink:  "#contact",
        BtnName: "get started"
    },
    {
        id: "2",
        SlideBgImg: `${slideBgImg2}`,
        Title: "",
        TopTitle: "With a commitment to excellence, innovation, and safety and sustainability,",
        Content:
            " we strive to delivering value through the consistency in providing high quality services.",
        BtnLink:  "#contact",
        BtnName: "get started"
    },
    {
        id: "3",
        SlideBgImg: `${slideBgImg3}`,
        Title: "",
        TopTitle: "And, with 14 years of experience, we aim to ",
        Content:
            "provide the best-in-class services to our clients.",
        BtnLink:  "#contact",
        BtnName: "get started"
    }
];

export default function BannerOne() {
  return (
    <>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className="homepage-slides"
      >
        {bannerData && bannerData.map((banner) => (
            <SwiperSlide key={banner.id}>
                <div
                    className="single-slider-item"
                    style={{ backgroundImage: `url(${banner.SlideBgImg})` }}
                >
                    <div className="diplay-table">
                        <div className="display-table-cell">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7" >
                                        <span className="hero-text">

                                                <div className="text-container">
                                                    <h2>{banner.Title}</h2>
                                                    {banner.TopTitle}<br />
                                                    {banner.Content}
                                                </div>

                                        </span>

                                        {/*<div className="center-wrap">
                                            <div className="button">
                                                <a href={banner.BtnLink}>{banner.BtnName} <i className="icofont-long-arrow-right"></i></a>
                                                <div className="mask"></div>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
