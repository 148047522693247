import React, { Component } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import PropTypes from "prop-types";

class Testimonials extends Component {
  render() {
    //Testimonials loop start
    const testimonialsitem = this.props.testimonialsData.map((testimonials, index) => (
        <SwiperSlide key={index}>
            <div className="single-testimonial-item text-center">
                <i className="icofont-quote-left" />
                <p>{testimonials.Content}</p>

                <div className="client-profile">
                    <img src={testimonials.clientImage} alt="client-one" />
                </div>
                
                <div className="client-info">
                    <h3>{testimonials.Name}</h3>
                    <span>{testimonials.Profession}</span>
                </div>
            </div>
        </SwiperSlide>
    ));
    //Testimonials loop END
    return (
        <>
            <section className="testimonials ptb-100">
                <div className="container">
                    <Swiper 
                        navigation={true} 
                        modules={[Navigation]} 
                        className="testimonial-slides"
                    >
                        {testimonialsitem}
                    </Swiper> 
                </div>
            </section>
        </>
    );
  }
}

//Props Types
Testimonials.propTypes = {
    testimonialsData: PropTypes.array
};

//Default Props
Testimonials.defaultProps = { 
    testimonialsData: [
        {
            clientImage: require("../assets/img/client-one.png"),
            Content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.",
            Name: "Jason Statham",
            Profession: "Founder & Director",
        },
        {
            clientImage: require("../assets/img/client-one.png"),
            Content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fermentum sagittis nulla, non vehicula mauris rutrum vitae. Sed non consequat dolor. Cras in odio augue.",
            Name: "Jason Statham",
            Profession: "Founder & Director",
        },
    ]
};

export default Testimonials;
