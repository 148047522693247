import React, { Component } from "react";
import PropTypes from "prop-types";
import Lightbox from "react-image-lightbox";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const images = [
    require("../assets/img/work-1.jpg"),
    require("../assets/img/work-2.jpg"),
    require("../assets/img/work-3.jpg"),
    require("../assets/img/work-4.jpg"),
    require("../assets/img/work-5.jpg"),
    require("../assets/img/work-6.jpg"),
    require("../assets/img/work-7.jpg"),
    require("../assets/img/work-8.jpg"),
    require("../assets/img/work-9.jpg"),
    require("../assets/img/work-10.jpg"),
    require("../assets/img/work-11.jpg"),
    require("../assets/img/work-12.jpg"),
    require("../assets/img/work-13.jpg"),
    require("../assets/img/work-14.jpg"),
    require("../assets/img/work-15.jpg"),
    require("../assets/img/work-16.jpg"),
    require("../assets/img/work-17.jpg"),
    require("../assets/img/work-18.jpg"),
    require("../assets/img/work-19.jpg"),
    require("../assets/img/work-20.jpg"),
    require("../assets/img/work-21.jpg")
  ];

const smallImages = [
      require("../assets/img/work-1.jpg"),
      require("../assets/img/work-2.jpg"),
      require("../assets/img/work-3.jpg"),
      require("../assets/img/work-4.jpg"),
      require("../assets/img/work-5.jpg"),
      require("../assets/img/work-6.jpg"),
      require("../assets/img/work-7.jpg"),
      require("../assets/img/work-8.jpg"),
      require("../assets/img/work-9.jpg"),
      require("../assets/img/work-10.jpg"),
      require("../assets/img/work-11.jpg"),
      require("../assets/img/work-12.jpg"),
      require("../assets/img/work-13.jpg"),
      require("../assets/img/work-14.jpg"),
      require("../assets/img/work-15.jpg"),
      require("../assets/img/work-16.jpg"),
      require("../assets/img/work-17.jpg"),
      require("../assets/img/work-18.jpg"),
      require("../assets/img/work-19.jpg"),
      require("../assets/img/work-20.jpg"),
      require("../assets/img/work-21.jpg")
  ];

const imagesDesc = [
   '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
];

class Works extends Component {
    constructor(props) {
        super(props);

        this.state = {
          photoIndex: 0,
          isOpen: false
        };
      }

    render() {
        const { photoIndex, isOpen } = this.state;
        return (
            <>
                <section id="works" className="our-works ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <div
                                    className="section-title"
                                    data-aos="fade-up"
                                    data-aos-delay="30"
                                    data-aos-duration="1000"
                                >
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">
                                        {this.props.SectionbgTitle}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Container>
                        <div className="mdb-lightbox no-margin">
                            <Row>

                                {images.map((image, index) => (

                                        <Col sm={6} lg={4}>
                                            <div className="work-details">
                                                <figure>
                                                    <img
                                                        src={smallImages[index]}
                                                        alt="Gallery"
                                                        className="img-fluid"
                                                    />

                                                    <div className="box-content">

                                                        <ul className="icon">
                                                            <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: index, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </figure>
                                            </div>
                                            <div class="centered-content">
                                                <p>{imagesDesc[index]}</p>
                                            </div>

                                        </Col>


                                ))}



                                {/*<Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                            src={smallImages[0]}
                                            alt="Gallery"
                                            className="img-fluid"
                                            />
                                            <p>Hello World</p>
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 0, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                            src={smallImages[1]}
                                            alt="Gallery"
                                            className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 1, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                            src={smallImages[2]}
                                            alt="Gallery"
                                            className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 2, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>


                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                            src={smallImages[3]}
                                            alt="Gallery"
                                            className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 3, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                            src={smallImages[4]}
                                            alt="Gallery"
                                            className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 4, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                            src={smallImages[5]}
                                            alt="Gallery"
                                            className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 5, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>


                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[6]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 3, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[7]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 4, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[8]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 5, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>


                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[9]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 0, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[10]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 1, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[11]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 2, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>


                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[12]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 3, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[13]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 4, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[14]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 5, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>


                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[15]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 3, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[16]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 4, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[17]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 5, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>


                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[18]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 3, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[19]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 4, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>

                                <Col sm={6} lg={4}>
                                    <div className="work-details">
                                        <figure>
                                            <img
                                                src={smallImages[20]}
                                                alt="Gallery"
                                                className="img-fluid"
                                            />
                                            <div className="box-content">

                                                <ul className="icon">
                                                    <li>
                                                        <span
                                                            href= "ll"
                                                            onClick={() => this.setState({ photoIndex: 5, isOpen: true })}
                                                            className="popup-btn">
                                                            <i className="icofont-search-2" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figure>
                                    </div>
                                </Col>*/}
                            </Row>
                        </div>

                        {isOpen && (
                            <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                imageTitle={photoIndex + 1 + "/" + images.length + '    ' + imagesDesc[photoIndex]}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + images.length - 1) % images.length
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % images.length
                                    })
                                }
                            />
                        )}
                    </Container>
                </section>
            </>
        );
    }
}
//Props Types
Works.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
};

//Default Props
Works.defaultProps = {
    SectionbgTitle: "project",
    sectionTitle: "gallery",
    sectionDescription:
        "Explore Our Success Stories! Journey through a collection of snapshots showcasing our successful projects, meticulously managed facilities, and satisfied clients.",
};

export default Works;
