import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

class Team extends Component {
  render() {
    //Team loop start
    const teamdata = this.props.teamsData.map((team, index) => (
        <SwiperSlide key={index}>
            <div className="team-box">
                <img src={team.Image} alt="Description" />
                <div className="box-content">
                    <div className="box-inner-content">
                        <h3 className="title">{team.Name}</h3>
                        <span className="post">{team.Profession}</span>
                        <ul className="icon">
                            <li><a href={team.facebookLink} target="_blank" rel="noreferrer"><i className="icofont-facebook" /></a></li>
                            <li><a href={team.linkedinLink} target="_blank" rel="noreferrer"><i className="icofont-linkedin" /></a></li>
                            <li><a href={team.twitterLink} target="_blank" rel="noreferrer"><i className="icofont-twitter" /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </SwiperSlide>
    ));
    //Team loop END
    return (
        <>
            <section id="team" className="our-team ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div 
                                className="section-title"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                            >
                                <h2>{this.props.sectionTitle}</h2>
                                <p>{this.props.sectionDescription}</p>
                                <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                576: {
                                    slidesPerView: 2,
                                },
                                768: {
                                    slidesPerView: 3,
                                }
                            }}
                            modules={[Pagination]}
                            className="team-slides"
                        >
                            {teamdata}
                        </Swiper>
                    </div>
                </div>
            </section>   
        </>
    );
  }
}
//Props Types
Team.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    teamsData: PropTypes.array
};

//Default Props
Team.defaultProps = {
    SectionbgTitle: "Team",
    sectionTitle: "Our Team",
    sectionDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.",
        teamsData: [
        {
            Image: require("../assets/img/team-one.jpg"),
            Name: "Williamson",
            Profession: "Web Developer",
            facebookLink: "https://www.facebook.com/",
            linkedinLink: "https://twitter.com/",
            twitterLink: "https://www.linkedin.com/",
        },
        {
            Image: require("../assets/img/team-two.jpg"),
            Name: "Jone Doy",
            Profession: "Designer",
            facebookLink: "https://www.facebook.com/",
            linkedinLink: "https://twitter.com/",
            twitterLink: "https://www.linkedin.com/",
        },
        {
            Image: require("../assets/img/team-three.jpg"),
            Name: "Oliver",
            Profession: "Photographer",
            facebookLink: "https://www.facebook.com/",
            linkedinLink: "https://twitter.com/",
            twitterLink: "https://www.linkedin.com/",
        },
        {
            Image: require("../assets/img/team-two.jpg"),
            Name: "Mark Jone",
            Profession: "CEO",
            facebookLink: "https://www.facebook.com/",
            linkedinLink: "https://twitter.com/",
            twitterLink: "https://www.linkedin.com/",
        },
        
    ]
};

export default Team;
