import React, { Component } from "react";
import PropTypes from "prop-types";

class Footer extends Component {
    render() {
        return (
            <>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <p className="copyright">{this.props.copyrightText}</p>
                            </div>
                            <div className="col-md-7">
                                <div className="social-icons bottom">
                                    <ul className="list-inline">
                                        <li>{this.props.socialTitle} </li>
                                        <li>
                                            <a href={this.props.FacebookLink} target="_blank" rel="noreferrer">
                                                <i className="icofont-facebook"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={this.props.TwitterLink} target="_blank" rel="noreferrer">
                                                <i className="icofont-twitter"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={this.props.InstagramLink} target="_blank" rel="noreferrer">
                                                <i className="icofont-instagram"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={this.props.linkedinLink} target="_blank" rel="noreferrer">
                                                <i className="icofont-linkedin"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}
//Props Types
Footer.propTypes = {
    copyrightText: PropTypes.string,
    FacebookLink: PropTypes.string,
    TwitterLink: PropTypes.string,
    InstagramLink: PropTypes.string,
    linkedinLink: PropTypes.string,
};

//Default Props
Footer.defaultProps = {
    copyrightText: "© 2023 VDS Services All Rights Reserved.",
    socialTitle: "Follow Us On:",
    FacebookLink: "https://www.facebook.com/",
    TwitterLink: "https://twitter.com/",
    InstagramLink: "https://www.instagram.com/",
    linkedinLink: "https://www.linkedin.com/",
};
export default Footer;
